import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import LightboxModal from 'components/LightboxModal';
import queryString from 'query-string';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import ConversationDetails from './Components/ConversationDetails';
import { getPatientConversations, showConversationDetails } from './action';
import { BackButton } from '../../components/UtilityComponents';
import ConversationsList from './Components/ConversationsList';
import './style.scss';

const Conversations = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const urlQuery = queryString.parse(location.search);
  const procedureId = parseInt(params.procedureId, 10) || -1;

  const conversations = useSelector(store => store.conversations.conversations);
  const selectedPhaseId = useSelector(
    store => store.currentPatientInfo.selectedPhaseId
  );
  const userProcedures = useSelector(
    store => store.currentPatientInfo.patient.userProcedures
  );

  const [conversationId, setConversationId] = useState(urlQuery.conversationId);
  const [page, setPage] = useState(1);

  const compositeProcedure = userProcedures.find(up => up.id === procedureId);

  useEffect(() => {
    if (props.isActive && compositeProcedure)
      dispatch(
        getPatientConversations(
          compositeProcedure.compositeProcedureId,
          selectedPhaseId,
          page
        )
      );
  }, [page, props.isActive, compositeProcedure]);

  useEffect(() => {
    setConversationId(queryString.parse(location.search).conversation_id);
  }, [location]);

  return (
    <>
      {conversationId ? (
        <div>
          <ConversationDetails selectedConversationId={conversationId} />
        </div>
      ) : (
        <ConversationsList
          conversations={conversations}
          handlePagination={page => setPage(page)}
        />
      )}
    </>
  );
};

export default Conversations;
