const applicationName = 'InsightPro™';

export default {
  APPLICATION_NAME: applicationName,
  EDIT_APPOINTMENT_CONFIRMATION_MODAL:
    'Changing the date/time in this app is not an official re-scheduling of your appointment. If you are seeking to change your appointment, you must contact your provider to do so. Once that step is completed, you may edit the appointment in this app.',
  SELECT_PROCEDURE_NOTE:
    'Please select a procedure to view. You can always select a different procedure at any time at the top of your screen.',
  DID_NOT_RECEIVE_VERIFICATION_CODE: "Didn't receive your code?",
  MSG_ENTER_VERIFICATION_CODE:
    'Please enter the Verification Code sent to mobile number',
  BTN_RESENT_CODE: 'Resend Code',
  LBL_EDIT_APPT: 'Important:',
  MSG_EDIT_APPT: `If your procedure has been scheduled, do not leave it blank. ${applicationName} is most helpful to you if we can give you tasks and reminders in relation to your procedure date.`,
  MSG_EDIT_APPT_SMALL:
    'You may ignore if your procedure date/time is yet to be decided.',
  SWITCH_PROCEDURE_CONFIRMATION_MODAL:
    'Are you sure you want to change your procedure view from %procedure1 to %procedure2',
  NO_RESULTS_FOUND_HEADER: 'No results found for "%searchTerm".',
  NO_RESULTS_SUBTEXT1:
    '# Try searching using a different keyword or check your spelling.',
  NO_RESULTS_SUBTEXT2:
    "# You can also create a new question if you didn't find what you were looking for.",
  WELCOME_HEADER: `Welcome to ${applicationName}!`,
  WELCOME_HEADER_BODY: 'Thank you for signing up',
  WELCOME_MSG_1:
    'To begin, we will need to gather some information relating to your procedure.',
  WELCOME_MSG_2:
    'This will allow us to send you relevant notifications and tasks at specific times throughout your surgical journey.',
  WELCOME_MSG_1_INVITED_USER: `Welcome to ${applicationName}, Important updates related to your procedure will appear on the notifications tab.`,
  WELCOME_MSG_2_INVITED_USER: `Thank you for giving us a chance to serve you - Team ${applicationName}.`,
  WELCOME_MSG_FOOTER: 'Best of luck!',
  INVITE_CODE_CREATE_PROCEDURE:
    'If you want to create a new procedure, please provide the invite code below.',
  MSG_PROCEDURE_COMPLETED:
    'since your procedure is complete, all the information related to it has been deleted.',
  REQUEST_DOWNLOAD_DATA_INFORMATION: `You can download a copy of your ${applicationName} information. Once your data is prepared for download, we will inform you via email/text about the same.`,
  PASSWORD_CONSTRAINT_INFORMATION:
    'Password must have at least 10 characters, 1 uppercase, 1 lowercase letter and 1 number.',
  SUPPORT_EMAIL: 'support@insightpro.app',
  SUPPORT_CONTACT_NUMBER: 'tel:1-866-704-4447',
  SUPPORT_CONTACT_NUMBER_STRING: '1-866-704-4447',
  LOGIN_SCREEN_MESSAGE1:
    'As the Study Clinic Team, InsightPro™ clinical trial app provides the tools to guide the subject through the consenting process, collect survey responses, monitor subject progress, and provide support during the life of the study.',
  PATIENT_REGISTER_MOBILE_VERIFICATION_PROMPT:
    'Enter the new verification code sent to:',
  LOGIN_SCREEN_MESSAGE2:
    'As a Medtronic Representative, InsightPro™ clinical trial app gives you visibility and tools to navigate which patients are on-and-off-track, so you can provide the necessary technical support to help them achieve optimal outcomes.',
  REMAIND_TASK_PATIENT_NOT_LOGGED_IN_MESSAGE:
    'This patient has not yet logged into the app, so your reminder will only be viewed by the patient if they choose to log in. You may want check in with the patient outside of the application to make sure they are on-track.',
  CONTACT_US_INFO_STRING1: 'Feel free to contact us via email or phone number.',
  CONTACT_US_INFO_STRING2: 'We would be happy to address your queries.',
  ORGANIZATION_COPY_RIGHT_STRING: 'Higgs Boson Inc, All Rights Reserved',
  CLEAR_SURVEY_INFO:
    'By entering your password below, you are confirming that this survey was submitted at an inappropriate time (i.e. when the subject was not supposed to receive the survey). After the survey is cleared, the following will occur:',
  SURVEY_ACTION_TEXT: 'Action: Please complete any surveys that are due as soon as possible.',
  TRIAL_SURVEY_DISCLAIMER: "Disclaimer: If you've submitted both AM & PM surveys, the bar represents your best reported pain score.",
};
