/*
 *
 * Common Constants
 *
 */

export const GET_DOC_AUTHORIZED_URL_REQUEST_SENT =
  'app/Common/GET_DOC_AUTHORIZED_URL_REQUEST_SENT';
export const GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED =
  'app/Common/GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED';
export const GET_DOC_AUTHORIZED_URL_REQUEST_FAILED =
  'app/Common/GET_DOC_AUTHORIZED_URL_REQUEST_FAILED';

export const DOCUMENT_UPLOAD_REQUEST_SENT =
  'app/Common/DOCUMENT_UPLOAD_REQUEST_SENT';
export const DOCUMENT_UPLOAD_REQUEST_SUCCEED =
  'app/Common/DOCUMENT_UPLOAD_REQUEST_SUCCEED';
export const DOCUMENT_UPLOAD_REQUEST_FAILED =
  'app/Common/DOCUMENT_UPLOAD_REQUEST_FAILED';

export const GET_PROCEDURE_LOCATIONS_REQUEST_SENT =
  'app/Common/GET_PROCEDURE_LOCATIONS_REQUEST_SENT';
export const GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED =
  'app/Common/GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED';
export const GET_PROCEDURE_LOCATIONS_REQUEST_FAILED =
  'app/Common/GET_PROCEDURE_LOCATIONS_REQUEST_FAILED';

export const GET_PROCEDURE_PROVIDERS_REQUEST_SENT =
  'app/Common/GET_PROCEDURE_PROVIDERS_REQUEST_SENT';
export const GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED =
  'app/Common/GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED';
export const GET_PROCEDURE_PROVIDERS_REQUEST_FAILED =
  'app/Common/GET_PROCEDURE_PROVIDERS_REQUEST_FAILED';

export const GET_COUNTER_SIGNE_REQUEST_SENT =
  'app/Common/GET_COUNTER_SIGNE_REQUEST_SENT';
export const GET_COUNTER_SIGNE_REQUEST_SUCCEED =
  'app/Common/GET_COUNTER_SIGNE_REQUEST_SUCCEED';
export const GET_COUNTER_SIGNE_REQUEST_FAILED =
  'app/Common/GET_COUNTER_SIGNE_REQUEST_FAILED';

export const GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SENT =
  'app/Common/GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SENT';
export const GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SUCCEED =
  'app/Common/GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SUCCEED';
export const GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_FAILED =
  'app/Common/GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_FAILED';

export const GET_PROCEDURE_MODIFIERS_REQUEST_SENT =
  'app/Common/GET_PROCEDURE_MODIFIERS_REQUEST_SENT';
export const GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED =
  'app/Common/GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED';
export const GET_PROCEDURE_MODIFIERS_REQUEST_FAILED =
  'app/Common/GET_PROCEDURE_MODIFIERS_REQUEST_FAILED';

export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT =
  'app/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT';
export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED =
  'app/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED';
export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED =
  'app/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED';
export const SET_REQUEST_SUCCEED_FLAG_FOR_UPDATE_USER_INFO =
  'app/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED';

export const SHOW_TOASTER = 'app/Common/SHOW_TOASTER';
export const REMOVE_TOASTER = 'app/Common/REMOVE_TOASTER';

export const GET_DEPARTMENT_PROCEDURES_REQUEST_SENT =
  'app/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_SENT';
export const GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED =
  'app/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED';
export const GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED =
  'app/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED';
export const DOCUMENT_UPLOAD_CLEAR_STATE =
  'app/Common/DOCUMENT_UPLOAD_CLAEAR_STATE';
export const DOCUMENT_UPLOAD_REMOVE_DOCUMENT =
  'app/Common/DOCUMENT_UPLOAD_REMOVE_DOCUMENT';

export const SAVE_SEARCH_FILTERS_SENT = 'app/Common/SAVE_SEARCH_FILTERS_SENT';
export const SAVE_SEARCH_FILTERS_SUCCEED =
  'app/Common/SAVE_SEARCH_FILTERS_SUCCEED';
export const SAVE_SEARCH_FILTERS_FAILED =
  'app/Common/SAVE_SEARCH_FILTERS_FAILED';

export const UPDATE_SEARCH_FILTERS_SENT =
  'app/Common/UPDATE_SEARCH_FILTERS_SENT';
export const UPDATE_SEARCH_FILTERS_SUCCEED =
  'app/Common/UPDATE_SEARCH_FILTERS_SUCCEED';
export const UPDATE_SEARCH_FILTERS_FAILED =
  'app/Common/UPDATE_SEARCH_FILTERS_FAILED';

export const DELETE_SEARCH_FILTERS_SENT =
  'app/Common/DELETE_SEARCH_FILTERS_SENT';
export const DELETE_SEARCH_FILTERS_SUCCEED =
  'app/Common/DELETE_SEARCH_FILTERS_SUCCEED';
export const DELETE_SEARCH_FILTERS_FAILED =
  'app/Common/DELETE_SEARCH_FILTERS_FAILED';

export const GET_DEPARTMENT_PROVIDERS_REQUEST_SENT =
  'app/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_SENT';
export const GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED =
  'app/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED =
  'app/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED';

export const ADD_NEW_PROCEDURE_REQUEST_SENT =
  'app/Dashboard/ADD_NEW_PROCEDURE_REQUEST_SENT';
export const ADD_NEW_PROCEDURE_REQUEST_SUCCEED =
  'app/Dashboard/ADD_NEW_PROCEDURE_REQUEST_SUCCEED';
export const ADD_NEW_PROCEDURE_REQUEST_FAILED =
  'app/Dashboard/ADD_NEW_PROCEDURE_REQUEST_FAILED';

export const CLEAR_ADD_PROCEDURE_STATES_REQUEST =
  'app/Dashboard/CLEAR_ADD_PROCEDURE_STATES_REQUEST';

export const GET_VERIFICATION_CODE_REQUEST_SENT =
  'app/Registration/GET_VERIFICATION_CODE_REQUEST_SENT';
export const GET_VERIFICATION_CODE_REQUEST_SUCCEED =
  'app/Registration/GET_VERIFICATION_CODE_REQUEST_SUCCEED';
export const GET_VERIFICATION_CODE_REQUEST_FAILED =
  'app/Registration/GET_VERIFICATION_CODE_REQUEST_FAILED';
export const CLEAR_VERIFICATION_CODE =
  'app/Registration/CLEAR_VERIFICATION_CODE';

export const VERIFY_OTP_REQUEST_SENT =
  'app/Registration/VERIFY_OTP_REQUEST_SENT';
export const VERIFY_OTP_REQUEST_SUCCEED =
  'app/Registration/VERIFY_OTP_REQUEST_SUCCEED';
export const VERIFY_OTP_REQUEST_FAILED =
  'app/Registration/VERIFY_OTP_REQUEST_FAILED';
export const CLEAR_VERIFY_OTP = 'app/Registration/CLEAR_VERIFY_OTP';

export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT =
  'app/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT';
export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED =
  'app/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED =
  'app/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED';

export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT =
  'app/Common/GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT';
export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED =
  'app/Common/GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED =
  'app/Common/GGET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED';

export const GET_LOCATION_REQUEST_SENT = 'app/Common/GET_LOCATION_REQUEST_SENT';
export const GET_LOCATION_REQUEST_SUCCEED =
  'app/Common/GET_LOCATION_REQUEST_SUCCEED';
export const GET_LOCATION_REQUEST_FAILED =
  'app/Common/GET_LOCATION_REQUEST_FAILED';

export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT =
  'app/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT';
export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED =
  'app/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED';
export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED =
  'app/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED';

export const VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT =
  'app/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT';
export const VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED =
  'app/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED';
export const VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED =
  'app/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED';

export const CLEAR_DATA = 'app/Common/CLEAR_DATA';

export const POST_SEND_INVITES_REQUEST_SENT =
  'app/PATIENT_CARD/POST_SEND_INVITES_REQUEST_SENT';
export const POST_SEND_INVITES_REQUEST_SUCCEED =
  'app/PATIENT_CARD/POST_SEND_INVITES_REQUEST_SUCCEED';
export const POST_SEND_INVITES_REQUEST_FAILED =
  'app/PATIENT_CARD/POST_SEND_INVITES_REQUEST_FAILED';

export const DISCARD_PROCEDURES_REQUEST_SENT =
  'app/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_SENT';
export const DISCARD_PROCEDURES_REQUEST_SUCCEED =
  'app/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_SUCCEED';
export const DISCARD_PROCEDURES_REQUEST_FAILED =
  'app/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_FAILED';

export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT =
  'app/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT';
export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED =
  'app/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED =
  'app/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED';

export const SHARE_OUTCOMES_REPORT_REQUEST_SENT =
  'app/Common/SHARE_OUTCOMES_REPORT_REQUEST_SENT';
export const SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED =
  'app/Common/SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED';
export const SHARE_OUTCOMES_REPORT_REQUEST_FAILED =
  'app/Common/SHARE_OUTCOMES_REPORT_REQUEST_FAILED';

export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT =
  'app/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT';
export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED =
  'app/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED';
export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED =
  'app/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED';

export const GET_SEARCH_CATEGORY_LIST_REQUEST_SENT =
  'app/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SENT';
export const GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED =
  'app/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED';
export const GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED =
  'app/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED';

export const SAVE_SUBJECT_INFORMATION = 'app/Common/SAVE_SUBJECT_INFORMATION';
export const CLEAR_SUBJECT_INFORMATION = 'app/Common/CLEAR_SUBJECT_INFORMATION';
export const SAVE_STUDY_INFORMATION = 'app/Common/SAVE_STUDY_INFORMATION';
export const CLEAR_STUDY_INFORMATION = 'app/Common/CLEAR_STUDY_INFORMATION';
export const SHOW_LOADER_FOR_ADD_PATIENT =
  'app/Common/SHOW_LOADER_FOR_ADD_PATIENT';
export const STOP_LOADER_FOR_ADD_PATIENT =
  'app/Common/STOP_LOADER_FOR_ADD_PATIENT';
export const GET_STATES_LIST_REQUEST_SENT =
  'app/Common/GET_STATES_LIST_REQUEST_SENT';
export const GET_STATES_LIST_REQUEST_SUCCEED =
  'app/Common/GET_STATES_LIST_REQUEST_SUCCEED';
export const GET_STATES_LIST_REQUEST_FAILED =
  'app/Common/GET_STATES_LIST_REQUEST_FAILED';

export const SET_USER_PROFILE_NAME_UPDATED =
  'app/Common/SET_USER_PROFILE_NAME_UPDATED';
export const RESET_USER_PROFILE_NAME_UPDATED =
  'app/Common/RESET_USER_PROFILE_NAME_UPDATED';
export const SET_USER_PROFILE_EMAIL_UPDATED =
  'app/Common/SET_USER_PROFILE_EMAIL_UPDATED';
export const RESET_USER_PROFILE_EMAIL_UPDATED =
  'app/Common/RESET_USER_PROFILE_EMAIL_UPDATED';
export const SET_USER_PROFILE_CONTACT_NUMBER_UPDATED =
  'app/Common/SET_USER_PROFILE_CONTACT_NUMBER_UPDATED';
export const RESET_USER_PROFILE_CONTACT_NUMBER_UPDATED =
  'app/Common/RESET_USER_PROFILE_CONTACT_NUMBER_UPDATED';
export const SET_USER_PROFILE_VERIFY_OTP_UPDATED =
  'app/Common/SET_USER_PROFILE_VERIFY_OTP_UPDATED';
export const RESET_USER_PROFILE_VERIFY_OTP_UPDATED =
  'app/Common/RESET_USER_PROFILE_VERIFY_OTP_UPDATED';
export const SET_CONFIRM_PASSWORD_DONE = 'app/Common/SET_CONFIRM_PASSWORD_DONE';
export const RESET_CONFIRM_PASSWORD_DONE =
  'app/Common/RESET_CONFIRM_PASSWORD_DONE';
export const SET_CONVERSATION_START_DONE =
  'app/Common/SET_CONVERSATION_START_DONE';
export const RESET_CONVERSATION_START_DONE =
  'app/Common/RESET_CONVERSATION_START_DONE';
export const SET_EDIT_PATIENT_STARTED = 'app/Common/SET_EDIT_PATIENT_STARTED';
export const RESET_EDIT_PATIENT_STARTED =
  'app/Common/RESET_EDIT_PATIENT_STARTED';
export const SET_ADD_VISIT_STARTED = 'app/Common/SET_ADD_VISIT_STARTED';
export const RESET_ADD_VISIT_STARTED = 'app/Common/RESET_ADD_VISIT_STARTED';
export const SET_EDIT_VISIT_STARTED = 'app/Common/SET_EDIT_VISIT_STARTED';
export const RESET_EDIT_VISIT_STARTED = 'app/Common/RESET_EDIT_VISIT_STARTED';
export const SET_EDIT_VISIT_NOTE_STARTED =
  'app/Common/SET_EDIT_VISIT_NOTE_STARTED';
export const RESET_EDIT_VISIT_NOTE_STARTED =
  'app/Common/RESET_EDIT_VISIT_NOTE_STARTED';
export const SET_EDIT_NOTE_STARTED = 'app/Common/SET_VISIT_NOTE_STARTED';
export const RESET_EDIT_NOTE_STARTED = 'app/Common/RESET_VISIT_NOTE_STARTED';
export const SET_PROFILE_PHOTO_UPLOAD_STARTED =
  'app/Common/SET_PROFILE_PHOTO_UPLOAD_STARTED';
export const RESET_PROFILE_PHOTO_UPLOAD_STARTED =
  'app/Common/RESET_PROFILE_PHOTO_UPLOAD_STARTED';
export const SET_PHASE_CHANGE_STARTED = 'app/Common/SET_PHASE_CHANGE_STARTED';
export const RESET_PHASE_CHANGE_STARTED =
  'app/Common/RESET_PHASE_CHANGE_STARTED';
export const SET_EXIT_STUDY_STARTED = 'app/Common/SET_EXIT_STUDY_STARTED';
export const RESET_EXIT_STUDY_STARTED = 'app/Common/RESET_EXIT_STUDY_STARTED';
export const SET_RESOLVE_CONVERSATION_STARTED =
  'app/Common/SET_RESOLVE_CONVERSATION_STARTED';
export const RESET_RESOLVE_CONVERSATION_STARTED =
  'app/Common/RESET_RESOLVE_CONVERSATION_STARTED';
export const SET_CHANGE_PASSWORD_STARTED =
  'app/Common/SET_CHANGE_PASSWORD_STARTED';
export const RESET_CHANGE_PASSWORD_STARTED =
  'app/Common/RESET_CHANGE_PASSWORD_STARTED';
export const SET_FETCH_TERMS_AND_CONDITIONS_STARTED =
  'app/Common/SET_FETCH_TERMS_AND_CONDITIONS_STARTED';
export const RESET_FETCH_TERMS_AND_CONDITIONS_STARTED =
  'app/Common/RESET_FETCH_TERMS_AND_CONDITIONS_STARTED';
export const SET_DELETE_APPOINTMENT_STARTED =
  'app/Common/SET_DELETE_APPOINTMENT_STARTED';
export const RESET_DELETE_APPOINTMENT_STARTED =
  'app/Common/RESET_DELETE_APPOINTMENT_STARTED';
export const SET_CREATE_PATIENT_STARTED =
  'app/Common/SET_CREATE_PATIENT_STARTED';
export const RESET_CREATE_PATIENT_STARTED =
  'app/Common/RESET_CREATE_PATIENT_STARTED';
export const SET_RESEND_INVITE_STARTED = 'app/Common/SET_RESEND_INVITE_STARTED';
export const RESET_RESEND_INVITE_STARTED =
  'app/Common/RESET_RESEND_INVITE_STARTED';
export const SET_SEND_MESSAGE_STARTED = 'app/Common/SET_SEND_MESSAGE_STARTED';
export const RESET_SEND_MESSAGE_STARTED =
  'app/Common/RESET_SEND_MESSAGE_STARTED';
export const SET_ADD_COMMENT_ON_NOTE_STARTED =
  'app/Common/SET_ADD_COMMENT_ON_NOTE_STARTED';
export const RESET_ADD_COMMENT_ON_NOTE_STARTED =
  'app/Common/RESET_ADD_COMMENT_ON_NOTE_STARTED';
export const INCREASE_API_CALLS_IN_PROGRESS_COUNTER =
  'app/Common/INCREASE_API_CALLS_IN_PROGRESS_COUNTER';
export const DECREASE_API_CALLS_IN_PROGRESS_COUNTER =
  'app/Common/DECREASE_API_CALLS_IN_PROGRESS_COUNTER';

export const ADD_REQUEST_AND_CALLBACK_IN_STORE =
  'app/Common/ADD_REQUEST_AND_CALLBACK_IN_STORE';
export const REMOVE_REQUEST_AND_CALLBACK_IN_STORE =
  'app/Common/REMOVE_REQUEST_AND_CALLBACK_IN_STORE';

export const SET_UPLOAD_PAPER_CONSENT_STARTED =
  'app/Common/SET_UPLOAD_PAPER_CONSENT_STARTED';
export const RESET_UPLOAD_PAPER_CONSENT_STARTED =
  'app/Common/RESET_UPLOAD_PAPER_CONSENT_STARTED';
export const FETCH_AUDIT_TRAILS_REQUEST_SUCCEED =
  'src/AuditTrails/FETCH_AUDIT_TRAILS_REQUEST_SUCCEED';
export const FETCH_AUDIT_TRAILS_REQUEST_FAILED =
  'src/AuditTrails/FETCH_AUDIT_TRAILS_REQUEST_FAILED';

export const FETCH_COHORTS_REQUEST_SUCCEED =
  'src/AuditTrails/FETCH_COHORTS_REQUEST_SUCCEED';
