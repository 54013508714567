import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pluralize } from 'utils/stringUtils';
import { PrimaryButton } from '../StyleGuideComponents/common/buttons';
import './pagination.scss';

const Pagination = props => {
  const { pagination, handlePagination, title } = props;
  const [pageNumber, setPageNumber] = useState(pagination && pagination.page);

  const goToPrevPage = () => {
    if (pagination.prevPage) {
      handlePagination(pagination.prevPage);
    }
  };

  const goToNextPage = () => {
    if (pagination.nextPage) {
      handlePagination(pagination.nextPage);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      goToPage();
    }
  };

  const onPageInputValueChange = e => {
    setPageNumber(parseInt(e.target.value));
  };

  const goToPage = () => {
    if (pageNumber && pageNumber <= pagination.totalPages && pageNumber > 0) {
      handlePagination(pageNumber);
    }
  };

  useEffect(() => {
    if (pagination.page !== pageNumber) {
      setPageNumber(pagination.page);
      if (!pagination.avoidScroll) {
        window.scrollTo(0, 0);
      }
    }
  }, [pagination]);

  const paginationTitle = title || 'Subjects';

  return pagination.total > 0 ? (
    <div className='pagination-container'>
      <div className='pagination-body-container text-right no-padding'>
        <span>
          {`${paginationTitle} ${pagination.startNumber}-${pagination.endNumber}`}{' '}
          <span>
            (of&nbsp;
            {pagination.total})
          </span>
        </span>
        <div className='go-to-page'>
          <div className='page-input'>
            <input
              type='number'
              min={1}
              value={pageNumber || ''}
              onChange={onPageInputValueChange}
              onKeyPress={handleKeyPress}
            />
          </div>
          <PrimaryButton
            className='btn-pagination goto-btn-pagination'
            onClick={goToPage}
          >
            <span style={{ fontSize: '12px', fontWeight: '400' }}>
              {' '}
              Go to Page{' '}
            </span>
          </PrimaryButton>
        </div>
        <div
          className={`prev-page page-nav-icon ${
            pagination.prevPage === null ? '' : ''
          }`}
          onClick={goToPrevPage}
        >
          <span className='icon icon-font-a-left-chevron' />
        </div>
        <div
          className={`next-page page-nav-icon ${
            pagination.nextPage === null ? '' : ''
          }`}
          onClick={goToNextPage}
        >
          <span className='icon icon-font-a-right-chevron' />
        </div>
      </div>
      <div className='clearfix' />
    </div>
  ) : null;
};

export default Pagination;
