import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SHOW_TEAM_MEMBER_SECTION,
  DISABLE_AGGREGATE_OUTCOMES,
  ACCESS_CONTROL_KEYS,
} from 'constants';
import DownloadFile from 'components/DownloadFile';
import { getTrainingMaterialDataLink } from 'services/common';
import PropTypes from 'prop-types';
import { PrimaryModal } from '../StyleGuideComponents/common/modals';
import { colors as variables } from '../StyleGuideComponents/StyleGuideCSS';

import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import './DashboardNavigationBar.scss';

import Strings from '../../strings';

const DashboardNavigationBar = props => {
  const history = useHistory();
  const Tabs = {
    home: 0,
    subjects: 1,
    'team-members': 2,
    reports: 3,
    admin: 4,
    payments: 5,
  };
  const {
    activeTab: activeTabInProps,
    queryLocation,
    clearFilterCallBack,
  } = props;
  const [activeTab, setActiveTab] = useState(Tabs[activeTabInProps]);
  const [showContactviewModal, setShowContactviewModal] = useState(false);
  const { appFeatures } = useSelector(store => store.currentUser);

  const isSuperUser = useSelector(
    store => store.currentUser.attributes.hasSuperUserPrivileges
  );
  const getPatientSearchRoute = () => {
    if (queryLocation) return `/search/patients${queryLocation.search}`;
    return `/search/patients/`;
  };

  useEffect(() => {
    switch (activeTab) {
      case 0:
        history.push('/');
        break;
      case 1: {
        const route = getPatientSearchRoute();
        history.push(route);
        break;
      }
      case 2:
        history.push('/manage-patient-advocates');
        break;
      case 3:
        history.push('/surveys');
        break;
      case 4:
        history.push('/admin/tab/1');
        break;
      case 5:
        history.push('/patients/payments');
        break;
      default:
        history.push('/');
    }
  }, [activeTab]);

  const switchTab = newTab => {
    if (newTab === 1 && newTab === activeTab) {
      clearFilterCallBack();
    }
    setActiveTab(newTab);
  };

  const getReportsButtonStyle = () => {
    if (!DISABLE_AGGREGATE_OUTCOMES) {
      if (activeTab === 3) {
        return { color: variables.colorPrimary };
      }
      return { color: variables.colorInactiveDarkGrey };
    }
    return { color: variables.colorInactiveGrey };
  };

  return (
    <div>
      <PrimaryModal
        show={showContactviewModal}
        onHide={() => setShowContactviewModal(false)}
        bodyHeader={{
          text: 'Contact Us',
        }}
        bodyContent={{
          text: (
            <div className='footer-content-container'>
              <div className='text'>
                Feel free to contact us via email or phone number.
              </div>
              <div className='text'>
                We would be happy to address your queries.
              </div>
              <div className='phone-container'>
                <i className='icon icon-font-a-contact' />
                <span className='phone'>1-866-704-4447</span>
              </div>
              <div className='email-container'>
                <i className='icon icon-font-a-email' />
                <span className='email'>{Strings.SUPPORT_EMAIL}</span>
              </div>
              <DownloadFile
                serviceCall={getTrainingMaterialDataLink}
                style={{ justifyContent: 'center' }}
              >
                <div className='aws-link-container'>
                  <span className='icon icon-download' />
                  <span className='link'>
                    Clinician Dashboard Overview Video
                  </span>
                </div>
              </DownloadFile>
            </div>
          ),
        }}
      />
      <div className='dashboard-navigation-bar'>
        <span
          className='dashboard-navigation-bar-item'
          onClick={() => switchTab(0)}
          role='button'
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <TertiaryButton
            style={
              activeTab === 0
                ? { color: variables.colorPrimary }
                : { color: variables.colorInactiveDarkGrey }
            }
          >
            Home
          </TertiaryButton>
        </span>
        <span className='dashboard-navigation-bar-item'>
          <TertiaryButton
            style={
              activeTab === 1
                ? { color: variables.colorPrimary }
                : { color: variables.colorInactiveDarkGrey }
            }
            onClick={() => switchTab(1)}
          >
            Subjects
          </TertiaryButton>
        </span>
        {!SHOW_TEAM_MEMBER_SECTION && (
          <span
            className='dashboard-navigation-bar-item'
            onClick={() => switchTab(2)}
            role='button'
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <TertiaryButton
              style={
                activeTab === 2
                  ? { color: variables.colorPrimary }
                  : { color: variables.colorInactiveDarkGrey }
              }
            >
              Team Members
            </TertiaryButton>
          </span>
        )}
        <span
          className='dashboard-navigation-bar-item'
          onClick={() => switchTab(3)}
          role='button'
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <TertiaryButton
            disabled={DISABLE_AGGREGATE_OUTCOMES}
            style={getReportsButtonStyle()}
          >
            Reports
          </TertiaryButton>
        </span>
        {isSuperUser ? (
          <span
            className='dashboard-navigation-bar-item'
            onClick={() => switchTab(4)}
            role='button'
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <TertiaryButton
              style={
                activeTab === 3
                  ? { color: variables.colorPrimary }
                  : { color: variables.colorInactiveDarkGrey }
              }
            >
              User Admin
            </TertiaryButton>
          </span>
        ) : (
          ''
        )}
        {appFeatures[ACCESS_CONTROL_KEYS.PAYMENT_HISTORY].canView ? (
          <span
            className='dashboard-navigation-bar-item'
            onClick={() => switchTab(5)}
            role='button'
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <TertiaryButton
              style={
                activeTab === 5
                  ? { color: variables.colorPrimary }
                  : { color: variables.colorInactiveDarkGrey }
              }
            >
              Payments
            </TertiaryButton>
          </span>
        ) : (
          ''
        )}
        <span className='dashboard-navigation-bar-item contact-us-item'>
          <div className='help-text-label'>Need help?</div>
          <TertiaryButton
            style={{ fontWeight: 'bold' }}
            onClick={() => setShowContactviewModal(true)}
          >
            Contact us
          </TertiaryButton>
        </span>
      </div>
    </div>
  );
};

DashboardNavigationBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryLocation: PropTypes.object,
  clearFilterCallBack: PropTypes.func,
};

DashboardNavigationBar.defaultProps = {
  queryLocation: null,
  clearFilterCallBack: () => {},
};
export default DashboardNavigationBar;
