import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import HelpBlock from 'components/HelpBlock';
import validate from 'common/validator';
import { PrimaryButton } from '../StyleGuideComponents/common/buttons';
import './lookup.scss';

const validationConfig = {
  fields: ['lookupValue'],
  rules: {
    lookupValue: [
      { rule: 'isRequired', message: 'Lookup value is required' },
      {
        rule: 'shouldExcludeChars',
        chars: ['%', '#'],
        message: 'Lookup value cannot have "%, #" characters',
      },
    ],
  },
};

const Lookup = props => {
  const { history } = props;
  const [lookupValue, setLookupValue] = useState('');
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState('all');

  const onFormValidationSuccess = () => {
    setErrors({});
    history.push(`/lookup/${lookupValue}/${category}`);
  };

  const onLookupValueChange = e => {
    setLookupValue(e.target.value);
    setErrors({});
  };

  const submitForm = () => {
    validate(
      validationConfig,
      { lookupValue },
      errors => setErrors(errors),
      onFormValidationSuccess
    );
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <form autoComplete='off'>
      <div
        className={`navbar-form navbar-left lookup ${
          errors.lookupValue !== undefined ? 'has-error' : ''
        }`}
      >
        <div className='form-group lookup-form'>
          <input
            type='text'
            className='form-control'
            value={lookupValue}
            onChange={onLookupValueChange}
            placeholder='Find Subjects by ID, MRN or Name'
            onKeyPress={handleKeyPress}
          />
          <span style={{ width: '80px' }}>
            <PrimaryButton
              style={{ borderRadius: '0 4px 4px 0', height: '40px' }}
              onClick={submitForm}
            >
              Search
            </PrimaryButton>
          </span>
        </div>
        <HelpBlock value={errors.lookupValue} />
      </div>
    </form>
  );
};

export default Lookup;
