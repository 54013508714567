import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { dateStringToMMSDateFormat } from 'utils/dateUtils';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import NoQuestions from 'assets/images/noquestions.png';
import Emptyview from 'components/Emptyview';
import Pagination from 'components/Pagination';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import PropTypes from 'prop-types';
import { ACCESS_CONTROL_KEYS } from 'constants';
import CreateConversation from './CreateConversation';
import ConversationsCard from './ConversationCard';

const ConversationList = props => {
  const { conversations, handlePagination } = props;
  const history = useHistory();

  let headerDate = null;
  const currentUserId = useSelector(store => store.currentUser.attributes.id);
  const patient = useSelector(store => store.currentPatientInfo.patient);
  const pagination = useSelector(store => store.conversations.pagination);
  const appFeatures = useSelector(store => store.currentUser.appFeatures);

  const [showStartConversationModal, setShowStartConversationModal] =
    useState(false);

  const onConversationClicked = conversationId => {
    history.push(
      `${window.location.pathname}?conversation_id=${conversationId}#messages`
    );
  };

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          <div className='header-left-section'>
            {conversations.length > 0 ? `${conversations.length} Messages` : ''}
          </div>

          <div className='header-right-section'>
            {appFeatures[ACCESS_CONTROL_KEYS.MESSAGES].canCreate ? (
              <SecondaryButton
                disabled={patient.signInCount === 0}
                style={{ width: '174px' }}
                onClick={() => setShowStartConversationModal(true)}
              >
                <ModeEditOutlinedIcon sx={{ fontSize: '2rem' }} />
                &nbsp; Compose Message
              </SecondaryButton>
            ) : (
              ''
            )}
          </div>
        </div>
      }
      body={
        <div className='conversations-list'>
          {conversations.length > 0 ? (
            conversations.map(item => {
              const startDateDisplay = dateStringToMMSDateFormat(
                item.updatedAt
              );
              if (startDateDisplay !== headerDate) {
                headerDate = startDateDisplay;
                return (
                  <ConversationsCard
                    key={item.id}
                    item={item}
                    showHeader
                    currentUserId={currentUserId}
                    startDateDisplay={startDateDisplay}
                    onConversationClicked={onConversationClicked}
                  />
                );
              }
              return (
                <ConversationsCard
                  key={item.id}
                  item={item}
                  showHeader={false}
                  currentUserId={currentUserId}
                  startDateDisplay={startDateDisplay}
                  onConversationClicked={onConversationClicked}
                />
              );
            })
          ) : (
            <Emptyview
              imgSrc={NoQuestions}
              className=''
              message='No Messages'
            />
          )}
          <CreateConversation
            showStartConversationModal={showStartConversationModal}
            onHideStartConversationModal={() =>
              setShowStartConversationModal(false)
            }
          />
        </div>
      }
      footer={
        <Pagination
          pagination={pagination}
          title='Messages'
          handlePagination={handlePagination}
        />
      }
    />
  );
};

ConversationList.propTypes = {
  conversations: PropTypes.array.isRequired,
  handlePagination: PropTypes.func.isRequired,
};

export default ConversationList;
