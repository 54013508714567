/** \n
 * @author Dilip Joshi
 * @desc The PatientDetails component is a page that displays detailed information about a patient.
 * It uses the PatientInfo component to display the basic information about the patient
 * and allows navigation through different tabs to display more specific information.
 *
 * Props:
 * - tabs: an array containing objects that describe the tabs available in the component.
 *         Each object should have the following properties:
 *         - id: a unique identifier for the tab
 *         - href: the URL path for the tab
 *         - title: an object with the following properties:
 *           - text: the text to display as the tab title
 *           - icon: (optional) a React component to display as the tab icon
 *           - keyForMissedData: (optional) a boolean value indicating if the tab is for missed data
 *           - count: (optional) the number of missed data items for the tab
 *         - component: the React component to render for the tab content
 *
 
 *
 * The component should be wrapped in a higher-order component using the withPatientDetails function
 * to provide it with the necessary props.
 */

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap/lib';
import PatientInfo from 'components/PatientInfo';
import PropTypes from 'prop-types';
import './patientDetails.scss';
import withPatientDetails from './configs/withPatientDetails';
import { getEnabledTabs } from './configs/services';

const PatientDetails = props => {
  const params = useParams();
  const history = useHistory();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];
  const userProcedures = useSelector(
    store => store.currentPatientInfo.patient.userProcedures
  );
  const compositeProcedure = userProcedures.find(up => up.id === procedureId);

  const [selectedTabId, setSelectedTabId] = useState(
    parseInt(params.tabId, 10)
  );

  useEffect(() => {
    setSelectedTabId(parseInt(params.tabId, 10));
  }, [params.tabId]);

  const getTitle = title => {
    return (
      <div className='tab-header'>
        {title?.icon}
        <span className='tab-menu-title'>{title?.text}</span>
        {(title.keyForMissedData && title.count) > 0 ? (
          <span className='count-container'>{title.count}</span>
        ) : (
          ''
        )}
      </div>
    );
  };

  const { tabs } = props;
  const enabledTabs = getEnabledTabs(tabs);

  return (
    <div className='patient-details'>
      <div className='patient-details-main-container'>
        <PatientInfo />

        <Tabs
          activeKey={selectedTabId}
          className={`tab-menu ${enabledTabs.length === 1 ? 'single-tab' : ''}`}
          id='patient-details-tab'
          justified
          onSelect={tabId => {
            const tab = enabledTabs.find(tabInArr => tabInArr.id === tabId);
            history.push(
              `/patients/${patientId}/procedures/${procedureId}/tab/${tabId}/${tab.href}`
            );
            setSelectedTabId(tabId);
          }}
        >
          {enabledTabs.map(tab => (
            <Tab
              eventKey={tab.id}
              href={tab.href}
              title={getTitle(tab.title)}
              key={tab.id}
            >
              <tab.component
                isActive={tab.id === selectedTabId}
                patientId={patientId}
                procedureId={procedureId}
                compositeProcedure={compositeProcedure}
                count={tab.title?.count}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

PatientDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withPatientDetails(PatientDetails);
